@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.eot');
  src: url('/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype');
  src: url('/fonts/Gilroy-Medium.woff') format('woff');
  src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-SemiBold.eot');
  src: url('/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype');
  src: url('/fonts/Gilroy-SemiBold.woff') format('woff');
  src: url('/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Bold.eot');
  src: url('/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype');
  src: url('/fonts/Gilroy-Bold.woff') format('woff');
  src: url('/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@media screen and (min-width: 240px) {
  .swiper-container {
    width: 240px;
  }
}

@media screen and (min-width: 320px) {
  .swiper-container {
    width: 320px;
  }
}

@media screen and (min-width: 480px) {
  .swiper-container {
    width: 480px;
  }
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-container {
    width: 1024px;
  }

  .DateInput_input {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-container {
    width: 1280px;
  }

  .DateInput_input {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
}

.CalendarDay__selected:hover,
.CalendarDay__selected {
  border-radius: 9999px;
  border: 0;
  background-color: #0e1e12 !important;
}
.CalendarDay__selected_span:hover,
.CalendarDay__selected_span {
  background-color: #efefef;
  border: none;
  color: #0e1e12;
  border-radius: 0 !important;
}

.CalendarDay__default {
  border: none;
  border-radius: 9999px;
}

.CalendarDay__selected_span:hover {
  box-shadow: 0 0 0 1px #0e1e12 inset;
  border-radius: 9999px !important;
}

.CalendarDay__default:hover {
  box-shadow: 0 0 0 1px #0e1e12 inset;
  border-radius: 9999px !important;
  border: none;
  background-color: #fff;
}

.CalendarMonth_caption, strong {
  color: #0e1e12;
  font-size: 18px;
  font-weight: 600 !important;
}

.DateRangePickerInput_arrow_svg {
  margin-left: 4px;
  margin-right: 4px;
}

.DateRangePickerInput__withBorder {
  border-radius: 9999px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.DateInput {
  background: none;
}

.DateInput_input {
  background: none;
  border-radius: 9999px;
  border-width: 0;
  text-align: center;
}

.DateInput_input__focused {
  border-bottom: 0;
  box-shadow: none !important;
  background: #efefef;
}

.fg-table th {
  padding: 0;
}

.fg-table td {
  padding: 0;
}